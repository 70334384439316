import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { WorkorderService } from 'src/app/services/workorder.service';
import { UtilityService } from 'src/app/services/utility.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-viewdocs-dialog',
  templateUrl: './viewdocs-dialog.component.html',
  styleUrls: ['./viewdocs-dialog.component.scss']
})
export class ViewdocsDialogComponent implements OnInit {
  fileTypes: any = [];
  files: any = [];
  loadFile: any = true;
  selectedImageCode: any;
  officeId: any;
  workorderID: any = this.workorderService.currentWorkorderID;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog, private workorderService: WorkorderService,
    private utilityService: UtilityService) { }

  ngOnInit(): void {
    this.loadFile = true;
    this.workorderService
      .getDocumentsForWorkorder(this.data.workOrderID, this.data.lineItemID)
      .subscribe((res) => {
        this.files = res;
        this.loadFile = false;
      });

      this.workorderService.getFileTypesForUpload().subscribe((res) => {
        this.fileTypes = res;
        console.log(this.fileTypes);
      });
      
     
  }

  imageType(imageCode){
    for(let file of this.fileTypes){
      if (file.ImageCode == imageCode){
        return file.ImageDescription;
      }
    }
  }

  openFile(file) {

    this.workorderService.openImageFile(file);

  }

  deleteFile(file) {

    Swal.fire({
      title: 'Delete?',
      text: "Are you sure you want to delete file?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {

      if (result.value) {
        //this.spinner.show();
        let body = {
          "ImageId": file.ImageId
        }

        this.workorderService.deleteFile(body, this.workorderID).subscribe((res: any) => {
          if (!res.Action) {
            Swal.fire({
              text: "File deleted successfully!",
              icon: 'success',
              confirmButtonText: 'OK',
            }).then(() => {
              this.dialog.closeAll();
            })
          }
          else {
            Swal.fire({
              text: res.ErrorMessage,
              icon: 'error',
              confirmButtonText: 'OK',
            }).then(() => {
              this.dialog.closeAll();
            })
          }
        })

      }
    });


  }

}
