<div style="float:right;top:-5px;right:-5px;color:white">
    <button mat-icon-button [mat-dialog-close]="true">
        <mat-icon>close</mat-icon>
      </button>  
</div> 

<h1 mat-dialog-title class="header" style="padding:0.5vw;font-size:1.2vw; color:white">View/Delete File</h1>

<div mat-dialog-content>

    <mat-card style="width: 40vw;">
        <mat-card-content>
            <div id="loading" *ngIf="loadFile">
                <mat-spinner [diameter]="25" color="primary"></mat-spinner>
            </div>

            <table class="content-table" style="width:100%;">
                <thead mat-header-cell style="width:100%;">
                    <th style="text-align: left;">File Type</th>
                    <th style="text-align: left;">Image</th>
                    <th>View</th>
                    <th>Delete</th>
                </thead>
                <tbody style="width:100%;">
                    <tr *ngFor="let file of files" style="width:100%;">
                        <td style="width:20%;">{{imageType(file.ImageCode)}}</td>
                        <td style="width:50%;">{{file.FileName}}</td>
                        <td style="width:15%;">
                            <button matTooltip="View" style="width: 100% !important;" mat-button (click)="openFile(file)">
                                <mat-icon>visibility</mat-icon>
                            </button>
                        </td>
                        <td style="width:15%;">
                            <button matTooltip="Delete" matTooltipPosition="right" style="width: 100% !important;" mat-button (click)="deleteFile(file)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                        
                    </tr>
                </tbody>
            </table>

            <!--
            <mat-list *ngIf="!loadFile">
                <mat-list-item *ngFor="let file of files">
                    <button matTooltip="Folder" style="width: 100% !important;" mat-button (click)="openFile(file)">{{file.FileName}}
                        <mat-icon>folder</mat-icon>
                    </button>
                    
                </mat-list-item>
            </mat-list>
            -->
        </mat-card-content>
    </mat-card>
</div>